
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import { VueUploadItem } from "vue-upload-component";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
    Grid,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '證照',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '證照類別清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '證照類別清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true,
        },
        {
          field: 'Type', title: '類型', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.CertificateType).find(e => e.Value === cellValue)?.Name : undefined
        },
        {
          field: "Ordinal", title: "排序", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100,
        },
        {
          field: "Published", title: "已發布", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100, formatter: ({ cellValue }) => cellValue ? '是' : '否'
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'Ordinal', order: 0 }, { column: 'CreatedTime', order: 1 });
          }
          return model.dispatch("certificate/query", params);
        } : undefined,
        queryAll: model ? () => model.dispatch('certificate/query') : undefined,
        save: model ? (params) => model.dispatch('certificate/save', params) : undefined
      },
      modalConfig: { width: 480, showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '名稱',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Type',
          title: '類型',
          span: 24,
          itemRender: { name: "$radio", options: model ? Object.values(model.enums.CertificateType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Published',
          title: '已發佈',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Type: [{ required: true }],
      }
    }

    return {
      model,
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("certificate/find", row.Id)
        : undefined;
      if (entity) {
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
